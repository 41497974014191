import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ProjectDetails2Header from "components/Project-details2-header/project-details2-header";
import ProjectIntroduction from "components/Project-introduction/project-introduction";
import ProjectGallery from "components/Project-gallery/project-gallery";
import ProjectDescription from "components/Project-description/project-description";
import NextProject from "components/Next-project/next-project";
import DarkTheme from "layouts/Dark";
import SEO from "components/seo/seo.jsx";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Parallax } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import { StaticImage } from "gatsby-plugin-image";
import LoadingScreen from "components/Loading-Screen";

SwiperCore.use([Pagination, Parallax]);

const ProjectDetails2Dark = (props) => {
  const projectData = props.pageContext.node;
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [theme, setTheme] = useState("Dark");

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }
  }, [navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  const [load, setLoad] = useState(false);
  const paginationRef = React.useRef(null);

  useEffect(() => {
    setLoad(true);
  }, [load]);

  return (
    <DarkTheme>
      <LoadingScreen/>
      <Navbar
        nr={navbarRef}
        lr={logoRef}
        theme={theme}
        themeModeChange={themeModeChange}
      />
      <div className="wrapper">
        <ProjectDetails2Header projectHeaderData={projectData} />
        <ProjectIntroduction projectIntroductionData={projectData} />
        <ProjectGallery projectGalleryData={projectData} props={props} />
        <ProjectDescription projectDescriptionData={projectData} />
        {/* <ProjectVideo projectVideoDate={ProjectDate} /> */}
        <NextProject projectCaseStudy={projectData} />

        {projectData &&
          projectData.additionalInfoLeftImage !== "" &&
          projectData.additionalInfoRightText !== "" && (
            <section className="next-prog digging-deeper">
              <div className="container px-4 py-5" id="custom-cards">
                <h2 className="pb-2 border-bottom">Digging Deeper</h2>

                <div className="row pt-3">
                  <div className="col-md-7 pt-4 desktop-padding">
                    <img loading ="lazy"
                      src={projectData.additionalInfoLeftImage.guid}
                      alt={projectData.projectName}
                      className="img-fluid"
                         
                    />
                  </div>

                  <div className="col-md-5 pt-4 desktop-padding">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: projectData.additionalInfoRightText,
                      }}
                      class="show-li"
                    ></p>
                  </div>
                </div>
              </div>
            </section>
          )}

        {projectData && projectData.additionalInfoBottom !== "" && (
          <section className="next-prog digging-deeper">
            <div className="container px-4 py-5" id="custom-cards">
              <h2 className="pb-2 border-bottom">Digging Deeper</h2>

              <div className="row pt-3">
                <div className="col-md-5 pt-4 desktop-padding">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: projectData.additionalInfoBottom,
                    }}
                    class="show-li"
                  ></p>
                </div>
              </div>
            </div>
          </section>
        )}

        {projectData &&
          projectData.testimonials.edges.length > 0 &&
          projectData.testimonials.edges[0].node.clientName !== "testing" && (
            <section className="app-testim section-padding bg-gray">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-7 col-md-10">
                    <div className="s-head text-center mb-80">
                      <h6 className="stit mb-30">
                        <span className="left"></span> Clients Feedback
                        <span className="right"></span>
                      </h6>
                      <h2>Valuable Clients Feedback About Our Services</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="swiper-container">
                      {load ? (
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={30}
                          speed={1000}
                          loop={false}
                          pagination={{
                            clickable: true,
                            el: paginationRef.current,
                          }}
                          className="swiper-wrapper"
                          breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 0,
                            },
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 0,
                            },
                            767: {
                              slidesPerView: 2,
                              centeredSlides: false,
                            },
                            991: {
                              slidesPerView: 3,
                            },
                          }}
                        >
                          {projectData.testimonials.edges.map((data, i) => (
                            <SwiperSlide className="swiper-slide" key={i}>
                              <div className="item">
                                <div className="icon mb-50">
                                  <StaticImage
                                    src="../../../static/img/mobile-app/qoute.png"
                                    alt=""
                                  />
                                </div>
                                <div className="text">
                                  <p>{data.node.clientTestimonial}</p>
                                </div>
                                <div className="info">
                                  <div className="img">
                                    <img loading ="lazy"
                                      src={data.node.featuredImage.node.guid}
                                      alt=""
                                         
                                    />
                                  </div>
                                  <div className="cont">
                                    <h6 className="mb-10">
                                      {data.node.clientName}
                                    </h6>
                                    <span>{data.node.clientDesignation}</span>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        ""
                      )}
                      <div
                        ref={paginationRef}
                        className="swiper-pagination"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = (props) => {
  const projectData = props.pageContext.node;
  const strippedDescription = stripHtmlTags(projectData?.projectDescription);
  console.log(projectData);
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";
  const shouldhideMetaTags = true;
    return (
    <>
      <SEO
        title={`Evolvan - Case Study of ${projectData?.projectName}`}
        description={`Evolvan Info Solutions Pvt. Ltd.`}
        ogurl={liveUrl} // Pass the live URL as the 'ogurl' prop
        ogtype={`article`}
        ogdescription={strippedDescription}
        ogimage={projectData?.featureImage.guid} // Pass the featureImage URL
        shouldhideMetaTags={shouldhideMetaTags}
        twittercard={`summary`}
        twitterdescription={strippedDescription}
        twittertitle={`Evolvan - Case Study of ${projectData?.projectName}`}
        twittersite={liveUrl}
        twitterimage={projectData?.featureImage.guid} // Pass the featureImage URL
        canonical={liveUrl}
        detail={`${projectData?.metaTagDetail}`}
        keywords={`${projectData?.metaTagKeyword}`}
      />
    </>
  );
};

function stripHtmlTags(htmlString) {
  if (!htmlString) return "";
  return htmlString.replace(/<[^>]+>/g, "");
}

function getLiveUrl() {
  if (typeof window !== "undefined") {
    return window.location.href;
  }
  return "";
}

export default ProjectDetails2Dark;